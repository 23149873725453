import { CircularProgress } from "@mui/material";
import { IconSize } from "@residently/components/tokens";
import { ComponentType } from "react";

import DefaultButton, { Props as ButtonProps } from "components/atoms/Button";

export interface Props extends ButtonProps {
  loading: boolean;
  Button?: ComponentType<ButtonProps>;
}

export default function LoadingButton({
  loading,
  disabled,
  color,
  Button = DefaultButton,
  children,
  ...props
}: Props) {
  return (
    <Button {...props} color={color} disabled={loading || disabled}>
      {loading ? (
        <CircularProgress size={IconSize.medium} color={color || "secondary"} />
      ) : (
        children
      )}
    </Button>
  );
}
