import { WebAuth } from "auth0-js";

import I18n from "services/I18n";

const makeWebAuth = () =>
  new WebAuth({
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || "",
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || "",
    redirectUri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI || "",
    responseType: "token",
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || "",
    scope: "email openid profile offline_access",
  });

export const startAuthFlow = (email: string) => {
  const webAuth = makeWebAuth();

  return new Promise((resolve, reject) => {
    webAuth.passwordlessStart(
      {
        connection: "email",
        send: "link",
        email,
      },
      (err, res) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(res);
      }
    );
  });
};

export const verifyCode = (email: string, code: string) => {
  const webAuth = makeWebAuth();

  return new Promise((resolve, reject) => {
    webAuth.passwordlessLogin(
      {
        connection: "email",
        email,
        verificationCode: code,
      },
      (err, res) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(res);
      }
    );
  });
};

export const handleRedirectCallback = () => {
  const webAuth = makeWebAuth();

  return new Promise((resolve, reject) => {
    webAuth.parseHash({ hash: window.location.hash }, (err, res) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(res);
    });
  });
};

export const formatErrorMessage = (errorMessage: string) => {
  switch (errorMessage) {
    case "The verification code has expired. Please try to login again.":
      return I18n.t("login.codeEntry.expiredError");
    case "Wrong email or verification code.":
      return I18n.t("login.codeEntry.wrongCodeError");
    case errorMessage.match(/^Your account has been blocked/)?.input:
      return I18n.t("login.codeEntry.blockedError");
    default:
      return errorMessage;
  }
};
