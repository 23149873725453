import { useContext } from "react";

import { CurrentOrganisationIdContext } from "context/CurrentOrganisationIdContext";

const useCurrentOrganisationIdContext = () => {
  const context = useContext(CurrentOrganisationIdContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentOrganisationId must be used within a CurrentOrganisationIdProvider"
    );
  }

  return context;
};

export default useCurrentOrganisationIdContext;
