import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Typography } from "@mui/material";
import { TextField } from "@residently/components";
import { Spacing } from "@residently/components/tokens";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import LoginLayout from "components/layouts/LoginLayout";

import LoadingButton from "components/atoms/LoadingButton";

import useCurrentOrganisationIdContext from "hooks/useCurrentOrganisationIdContext";

import I18n from "services/I18n";
import { startAuthFlow } from "services/auth";
import isEmailValid from "services/validators/isEmailValid";

export default function LoginIndex() {
  const router = useRouter();

  const { isAuthenticated } = useAuth0();
  const { setCurrentOrganisationId } = useCurrentOrganisationIdContext();

  const [email, setEmail] = useState<string | null>(null);
  const [emailSendingError, setEmailSendingError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      router.push("/dashboard");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setCurrentOrganisationId("");
    setEmail(localStorage.getItem("email"));
  }, []);

  const handleEmailChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
    setEmailSendingError(null);
  };

  const handleSubmit = async () => {
    if (!email) {
      return;
    }

    if (!isEmailValid(email)) {
      setEmailSendingError(I18n.t("login.index.error.invalid"));
      return;
    }

    try {
      setLoading(true);
      await startAuthFlow(email);

      localStorage.setItem("email", email);
      router.push("/login/confirmation");
    } catch {
      setEmailSendingError(I18n.t("login.index.error.unknown"));
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = ({ code }: { code: string }) => {
    if (code === "Enter") {
      handleSubmit();
    }
  };

  return (
    <LoginLayout>
      <Typography variant="h3" align="center" gutterBottom>
        {I18n.t("login.index.title")}
      </Typography>

      <TextField
        type="email"
        fullWidth
        label={I18n.t("login.index.label")}
        value={email || ""}
        onChange={handleEmailChange}
        onKeyPress={handleKeyPress}
        error={Boolean(emailSendingError)}
        helperText={emailSendingError}
      />

      <LoadingButton
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        onClick={handleSubmit}
        loading={loading}
      >
        {I18n.t("login.buttons.login")}
      </LoadingButton>
      <Stack direction="row" spacing={Spacing.xsmall} justifyContent="center">
        <Typography variant="body2" align="center" display="inline">
          {I18n.t("login.index.signup.text")}
        </Typography>
        <Typography variant="body2" color="secondary" display="inline">
          <a href={`${process.env.NEXT_PUBLIC_MARKETING_HOST}/#lead-form`}>
            {I18n.t("login.index.signup.ctaText")}
          </a>
        </Typography>
      </Stack>
    </LoginLayout>
  );
}
