import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { logEvent } from "firebase/analytics";
import { useCallback, useContext } from "react";

import { FirebaseContext } from "components/providers/FirebaseProvider";

export interface Props extends MuiButtonProps {
  gaEvent?: string;
  gaParams?: Record<string, any>;
}

const Button = (props: Props) => {
  const firebaseContext = useContext(FirebaseContext);
  const { gaEvent, gaParams, onClick, ...otherProps } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (gaEvent && firebaseContext?.analytics) {
        logEvent(firebaseContext.analytics, gaEvent, gaParams);
      }

      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleDoubleClick = useCallback(() => {}, []);

  return (
    <MuiButton
      {...otherProps}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    />
  );
};

export default Button;
